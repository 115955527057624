<template>


<div>

 <div class="page-banner">
   <img v-lazy="banner" class="img-center">
 </div>

<!--  <div class="page-loading" v-if="isShow">-->
<!--    <loading ref="isLoading"/>-->
<!--  </div>-->

  <div class="container">
    <div class="about padding-80">
        <div class="common-title">
             <h1>{{ $t("menu.about") }}</h1>
            <div class="common-line"></div>
        </div>
        <div class="about-text">
           <p>{{ about_us }}</p>
         </div>
      </div>
    <div class="advantage">
      <div class="common-title">
        <h1>{{ $t("advantage") }}</h1>
        <div class="common-line"></div>
      </div>
      <div class="advantage-item" v-for="item in our_advantage" :key="item">
        <div class="advantage-item-img">
          <img :src="item.image">
        </div>
        <div class="advantage-item-txt">
          <h3>{{item.title}}</h3>
         <p>
           {{item.description}}
         </p>

        </div>
      </div>

    </div>



  </div>

</div>
</template>

<script>

// import loading from "@/components/loading";
import {getAbout} from "@/network/main";

export default {
  name: "About",
  components:{
    // loading
  },
  data(){
    return{
      banner:{},
      about_us:{},
      our_advantage:[],
      isShow:true
    }
  },
  created() {

    getAbout().then(res => {
      this.banner = res.data.data.banner;
      this.about_us = res.data.data.about_us;
      this.our_advantage = res.data.data.our_advantage;
      this.isShow = false;
    })
  },

}
</script>

<style scoped>
.about-text{
  margin-top: 30px;
}
.about-text p{
  color: #666;
  white-space: pre-wrap;
}

.advantage-item{
  padding: 40px;
  margin: 30px 0;
  background: var(--color-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.advantage-item-txt{
  color: #fff;
  white-space: pre-wrap;
  width: calc( 100% - 146px);
}

.advantage-item-txt h3{
  margin-bottom: 10px;
}

.advantage-item-img{
  margin-right: 20px;
  width: 126px;
}




@media only screen and (min-width:0px) and (max-width:767px){
  .advantage-item{
    flex-wrap: wrap;
    padding: 20px;

  }

  .advantage-item-img{
    width: 100%;
    margin: 0;
  }

  .advantage-item-img img{
    display: block;
    margin: 0 auto;
  }

  .advantage-item-txt{
    margin-top: 20px;
    width: 100%;
  }


}

</style>